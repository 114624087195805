@import "vars";

.headerHolder {
  position: fixed;
  top: 0px;
  z-index: 100;
  width: 100%;
}

.root {
  height: 50px;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  background-color: $salmon;
}

.bg {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(222, 235, 225, 1) 0%,
    rgba(222, 235, 225, 0) 100%
  );
  height: 100px;
  top: 0;
}

.logo {
  position: absolute;
  display: block;
  margin-top: 5px;
  padding-left: 20px;
  width: 40px;
  height: 40px;
  background-image: url("./img/anthem/header-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
  text-decoration: none;

  @media (max-width: 580px) {
    // background-image: url("./img/2021/header-logo-narrow.png");
    // width: 244px;
  }
}

.links {
  display: flex;
  align-items: center;
}

.navLinkHolder {
  text-align: center;
}
.navLink {
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
  color: $navy;
  line-height: 50px;
  font-size: 20px;
}

.sponsoredBy {
  position: absolute;
  right: 0;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  height: 50px;

  color: black;
  background-image: url("./img/anthem/logos/anthem-website-logos_omidyar.svg");
  background-repeat: no-repeat;
  background-size: 100px 32.06px;
  background-position: center right;
  padding-right: 110px;
  line-height: 50px;
  margin-right: 10px;

  @media (max-width: 570px) {
    padding-right: 0;
    line-height: 15px;
    width: 100px;
    text-align: center;
    background-position-y: 15px;
  }
}

.alertHolder {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}

.alert {
  line-height: 1.1;
  text-align: center;
  background-color: #ddf777;
  color: $navy;
  text-transform: uppercase;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  // margin-top: -100%;
}

.showAlert {
  height: var(--alert-height);
}
