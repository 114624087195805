.text {
  background: url(../img/anthem/anthem-landing-logo.svg) center center no-repeat;
  background-size: contain;
  margin: 1rem auto;
  width: 100%;
  height: 200px;
}

.root {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.topText {
  font-size: 24px;
  margin-top: 10vh;
  text-transform: uppercase;
}

.bottomText {
  font-size: 60px;
  text-transform: uppercase;
  margin-bottom: 2vh;
}

.moreText {
  font-size: 24px;
  max-width: 700px;
  margin: 0 auto 10vh auto;
}
@media (max-width: 590px) {
  .content {
    padding: 0 20px;
  }
}
