@import "vars";
@import "~video-react/styles/scss/video-react";

@font-face {
  font-family: "Zichtbaar";
  font-weight: normal;
  src: url("./fonts/Zichtbaar-Regular.otf");
}

@font-face {
  font-family: "Zichtbaar";
  font-weight: bold;
  src: url("./fonts/Zichtbaar-Extrabold.otf");
}

html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  height: 100%;
}

a {
  color: #fff;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $bodyFont;

  // adjust letter spacing for Lichtaar
  letter-spacing: 0.01em;

  line-height: 1.2;
  background-color: $navy;
  background-size: cover;
  background-attachment: fixed;
  color: $font-color;
}

body {
  font-feature-settings: "liga", "kern";
}

/* resets */
* {
  box-sizing: border-box;
}

button,
input {
  font-family: $boldFont;
  background: none;
  border: 0;
  font-size: inherit;
  outline: none;
}
button,
// .inputlabel,
// .a-button {
//   background-color: $font-color;
//   padding: 14px 40px;
//   color: inherit;
//   cursor: pointer;
//   text-transform: uppercase;
//   line-height: 1;
//   transition: 300ms all;
//   text-decoration: none;

//   letter-spacing: 0.08em;
//   line-height: 1.2em;

//   &:hover {
//     background: #de01c7;
//   }

//   span {
//     display: block;
//     font-size: 32px;

//     @media (max-width: $mobile) {
//       font-size: inherit;
//     }
//   }
// }

// button,
// .inputlabel,
// .a-button {
//   background-color: $font-color;
//   padding: 14px 40px;
//   color: inherit;
//   cursor: pointer;
//   text-transform: uppercase;
//   line-height: 1;
//   transition: 300ms all;
//   text-decoration: none;
//   letter-spacing: 0.08em;
//   line-height: 1.2em;

//   &:hover {
//     background: #de01c7;
//   }

//   span {
//     display: block;
//     font-size: 32px;

//     @media (max-width: $mobile) {
//       font-size: inherit;
//     }
//   }
// }

// #root {
//   background: url(img/anthem/bg-triangle-left.svg) no-repeat;
//   background-position: left 60px;
// }

// .App {
//   background: url(img/anthem/bg-triangle-right.svg) no-repeat;
//   background-position: right 300px;
// }

.a-button {
  font-family: $boldFont;
}

.p-top {
  padding-top: 20px;
}

.flipped {
  background-color: $pink;
  color: #fff;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center center;
  transition: 300ms all;
  background-image: url(./img/heart-black.svg);
  background-size: auto 80%;

  &:hover {
    background-color: $pink;
    color: #fff;
    background-image: url(./img/heart-black.svg);
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
}

h1 {
  font-family: $newFont;
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
  // font-weight: normal;

  @media (max-width: $mobile) {
    font-size: 26px;
  }

  .plus {
    font-size: 42px;
    font-family: $newFontBold;

    @media (max-width: $mobile) {
      font-size: 24px;
    }
  }
}

h2 {
  font-family: $boldFont;
  font-size: 32px;
  line-height: 1;

  @media (max-width: $mobile) {
    font-size: 22px;
  }
  a {
    color: $salmon;
  }
}

.strong {
  font-family: $boldFont;
}

.fancy {
  font-family: $fancyFont;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: $mobile) {
    display: block;
  }
}

.flex-force {
  display: flex;
}

.full {
  height: 100vh;
}

.content {
  position: relative;
  z-index: 99;
  background: contain #000;
  background-size: cover;
  background-attachment: fixed;
  padding-top: $headerHeight;
  text-align: center;
  min-height: calc(100vh - 50px);

  .home-btn {
    position: absolute;
    display: block;
    top: $headerHeight;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    background: #fff;
    color: black;
    z-index: 10;

    &:hover {
      background: $pink;
      color: $yellow;
    }

    @media (max-width: $mobile) {
      width: 100%;
      position: relative;
      top: 0px;
      border-top: 1px solid #000;
    }
  }

  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
  }

  .asterisk {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    z-index: 2;
  }

  .section-wide {
    width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    max-width: 780px;

    @media (max-width: $mobile) {
      width: 90%;
    }
  }

  .section-extra-wide {
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    max-width: 1200px;

    @media (max-width: $mobile) {
      width: 97%;
    }

    .section {
      width: 90%;
    }
  }

  .section {
    width: 80%;
    margin: 0 auto;

    @media (max-width: $mobile) {
      width: 95%;
    }

    &-recorder {
      min-height: $minHeight;

      @media (max-width: $mobile) {
        min-height: calc(100vh - 140px);
      }
    }

    &-final {
      padding-top: 60px;
      min-height: $minHeight;
      /*video {
        width: 600px;
        height: 600px;

        @media(max-width: $mobile) {
          width: 100vw;
          height: 100vw;
        }
      }*/

      &-message {
        width: 600px;
        margin: 0 auto;
        margin-bottom: 40px;
        margin-top: 20px;

        a {
          display: inline;
        }

        @media (max-width: $mobile) {
          width: 90%;
        }
      }

      @media (max-width: $mobile) {
        min-height: inherit;
        padding-top: 20px;
      }

      &-wide-video {
        width: 800px;

        @media (max-width: $mobile) {
          width: 100vw;
        }
      }

      &-video-elements {
        background: center center no-repeat #000;
        background-size: cover;

        .video {
          margin: 0px auto;
        }

        .video-buttons {
          width: 600px;
          margin: 5px auto;
          padding: 0px;

          a {
            &:hover {
              color: $salmon;
            }
          }

          .side-by-side {
            display: flex;
            width: 50%;
            margin: 0px auto;
            padding: 20px 0px 100px 0px;
            div {
              width: 50%;

              a {
                transition: 300ms all;
                &:hover {
                  opacity: 0.5;
                }
              }
              img {
                height: 30px;
              }
            }
          }

          @media (max-width: $mobile) {
            width: 100%;
          }

          a {
            display: block;
          }
        }
      }
    }

    &-home {
      position: relative;
      z-index: 2;
      overflow: hidden;
      min-height: $minHeight;
    }
  }

  .gold-plant {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 45vw;
    z-index: 1;

    @media (max-width: $mobile) {
      width: 30vw;
    }
  }

  .section-left {
    width: 50%;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .video {
    background: #000;
    width: 600px;
    height: 600px;
    position: relative;
    margin: 0 auto;

    @media (max-width: $mobile) {
      width: 100%;
      height: inherit;
    }

    button {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .video-buttons {
    text-align: center;
    padding-left: 60px;

    a {
      color: #000;
      transition: 300ms all;
      &:hover {
        color: #666;
      }
    }
  }

  .countdown {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    font-size: 400px;
    color: $salmon;
    font-family: $boldFont;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 500ms fade-in forwards;
    opacity: 0;

    &-small {
      font-size: 22px;
      font-family: $bodyFont;
      color: #fff;
    }
  }
}

.three-d {
  width: 100%;
  height: 100%;
  user-select: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;

  &-message {
    position: absolute;
    bottom: 40px;
    left: 0px;
    width: 100%;
    color: #fff;
    text-align: center;

    span {
      color: $pink;
    }

    @media (max-width: $mobile) {
      width: 80%;
      left: 10%;
    }
  }
}

.browse {
  margin-top: $headerHeight + 10px;

  @media (max-width: $mobile) {
    margin-top: $borderHMobile;
  }

  .browse-grid {
    display: flex;

    @media (max-width: $mobile) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &-cell {
      flex: 0 0 25%;
      height: auto;
      background-position: center center;
      background-size: cover;

      @media (max-width: $mobile) {
        flex: 0 0 50%;
      }

      a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        transition: 300ms all;
        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }

        .play-btn {
          position: absolute;
          top: 0px;
          left: 0px;
          background: url(./img/playImg.svg) center center no-repeat $salmon;
          width: 40px;
          height: 40px;
          background-size: 15px;

          @media (max-width: $mobile) {
            width: 20px;
            height: 20px;
            top: 0px;
            left: 0px;
            background-size: 10px;
          }
        }

        .home-video-meta {
          left: 0px;
          bottom: 0px;

          .home-video-meta-sub {
            font-size: 13px;
            text-transform: uppercase;
            // color: $salmon;
            // background: $navy;
            max-width: 80%;

            @media (max-width: $mobile) {
              font-size: 10px;
            }
          }

          .home-video-meta-title {
            font-size: 22px;
            text-transform: uppercase;

            @media (max-width: $mobile) {
              font-size: 14px;
            }
          }
        }
      }

      &:before {
        content: "";
        float: left;
        padding-top: 100%;
      }
    }
  }

  .search-results-title {
    text-align: center;
    padding: 20px 0px 0px 0px;
  }

  &-content {
    width: 80%;
    margin: 60px auto;
    // position: relative;
    // z-index: 3;

    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile) {
      display: block;
      width: 95%;
    }

    .browse-nothing {
      width: 100%;
      margin: 0 auto;
      text-align: left;
      font-size: 32px;
      img {
        max-width: 100%;
      }
    }

    .cell-huh {
      width: 25%;
    }

    &-cell {
      position: relative;
      /*flex-basis: calc(50% - 0px);*/

      background: none;
      padding: 0px;
      margin: 0px;
      display: block;
      transition: 300ms all;
      text-decoration: none;
      color: #fff;

      &:hover {
        background: $salmon;
        color: #fff;

        .company-name {
          color: #fff;
        }

        .presenter {
          color: #fff;
        }
      }

      .play-img {
        position: relative;
      }

      .play-btn {
        position: absolute;
        top: 0px;
        left: 0px;
        background: url(./img/playImg.svg) center center no-repeat $salmon;
        width: 50px;
        height: 50px;
        background-size: 20px;

        @media (max-width: $mobile) {
          width: 40px;
          height: 40px;
          top: 0px;
          left: 0px;
          background-size: 10px;
        }
      }

      &-inner {
        margin: 20px;

        text-align: center;

        img {
          width: 100%;
          display: block;
        }
      }

      .gold {
        img {
          width: 100%;
        }
      }
    }
  }

  &-river {
    display: block;

    .browse-content-cell-inner {
      margin: 0;
      padding: 20px;

      @media (max-width: $mobile) {
        padding: 5px 0px;
      }
    }

    .play-btn {
      width: 30px;
      height: 30px;
      background-size: 15px;
    }

    img {
      max-width: 100%;
    }

    .playlist-title {
      font-size: 22px;
      font-family: $boldFont;
      text-align: center;
      text-transform: uppercase;
      color: $salmon;

      @media (max-width: $mobile) {
        font-size: 18px;
      }
    }
  }

  .playlist-river {
    margin-bottom: 100px;
    position: relative;

    @media (max-width: $mobile) {
      margin-bottom: 40px;
    }

    .arrow-previous,
    .arrow-next {
      padding: 10px;
      margin: 0px;
      top: 44%;
      transform: translateY(-50%);
      background: #fff;

      &:hover {
        background: $salmon;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .arrow-previous {
      position: absolute;
      left: -40px;
    }

    .arrow-next {
      position: absolute;
      right: -40px;
    }
  }

  .playlist-slide {
    /*padding: 10px;*/
  }
}

.browse-header {
  padding: 0px 40px 0px 40px;
  font-family: $fancyFont;
  text-align: center;
  position: relative;

  @media (max-width: $mobile) {
    padding: 40px 20px;
  }

  @media (max-width: $mobile) {
    padding-top: 90px;
  }

  .browse-gl {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  h1 {
    font-size: 72px;
    font-family: $newFontBold;
    margin: 0;
    padding: 10px 0px;

    @media (max-width: $mobile) {
      font-size: 42px;
    }
  }

  &-pre {
    font-size: 16px;
    text-transform: uppercase;
  }

  &-sub {
    font-size: 14px;
    font-family: $newFont;
    text-transform: uppercase;
    padding-bottom: 40px;
    width: 90%;
    margin: 0 auto;

    @media (max-width: $mobile) {
      font-size: 11px;
      padding-bottom: 20px;
    }
  }
}

.browse-searchbar {
  background-color: #d6bfbb;
  background-position: center;
  padding: 20px 40px 30px 40px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 30px 20px;
  }

  a {
    display: block;
    position: absolute;
    bottom: 7px;
    left: 40px;

    @media (max-width: $mobile) {
      left: 20px;
      bottom: 5px;
    }
  }

  div {
    margin: 0 auto;

    .clear {
      display: block;
      color: $navy;
      padding-top: 10px;
      position: absolute;
      text-transform: uppercase;

      &-hide {
        opacity: 0;
      }
    }

    input {
      width: 100%;
      border-bottom: 3px solid #fff;
      font-family: $boldFont;
      font-size: 22px;
      padding: 5px 0px;
      text-transform: uppercase;
      background: url(./img/search.svg) no-repeat right center;
      background-size: 20px;
      color: $navy;

      @media (max-width: $mobile) {
        font-size: 22px;
      }

      &::placeholder {
        color: $navy;
      }
    }
  }
}

.speech-page {
  text-align: center;
  padding-top: calc(#{$borderH} + 20px);
  min-height: calc(100vh - #{$borderH});

  @media (max-width: $mobile) {
    min-height: inherit;
  }

  &-inner {
    width: 100%;
    max-width: 660px;
  }

  &-wide {
    .speech-page-inner {
      width: 80%;
    }
  }

  .video-stack {
    margin: 0 auto;
  }

  .speech-info {
    padding-bottom: 100px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    max-width: 950px;

    h1,
    h2 {
      padding: 0px;
      margin: 10px 0px;
      line-height: 1;
      text-transform: uppercase;
    }

    .work-url {
      color: #ccc;
      padding: 20px 0px;
      display: block;
      transition: 300ms all;

      &:hover {
        color: $pink;
      }
    }

    @media (max-width: $mobile) {
      padding-bottom: 20px;
      width: 95%;
      margin: 0 auto;
    }

    .side-by-side {
      display: flex;
      width: 150px;
      margin: 0px auto;
      padding: 20px 0px 100px 0px;

      div {
        width: 50%;

        a {
          transition: 300ms all;
          &:hover {
            opacity: 0.5;
          }
        }
        img {
          height: 30px;
        }
      }
    }
  }
}

.progress {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  display: none;

  &-cell {
    height: 5px;
    width: 40px;
    margin: 0px 5px;
    background: #999;

    &-on {
      background: #000;
    }
  }
}

.recorder {
  .record-video {
    width: 360px;
    height: 640px;
    background: #000;
  }

  .video-webcam video {
    width: 600px;
  }

  p {
    strong {
    }
  }

  .recording {
    position: absolute;
    top: 14px;
    left: 10px;
    font-size: 32px;
    font-weight: bold;
    color: red;
    animation: 1s blink infinite;
  }

  .countdown-progress {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 10px;
    background: $salmon;
    animation: 15s countdown forwards linear;
  }
}

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputlabel {
  display: inline-block;
  font-family: $boldFont;
}

.inputfile:focus,
.inputlabel:focus,
.inputlabel:hover {
  /* background-color: red;*/
}

.home-logo {
  width: 80%;
  margin: 0 auto;

  @media (max-height: 550px) and (min-width: $mobile) {
    width: 50%;
    padding-top: 20px;
  }
}

.home {
  &-opening {
    background: url(./img/marble.jpg) center center no-repeat;
    background-size: cover;

    video {
      height: 512px;
      width: 512px;
      background: #000;
    }
  }

  &-section-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(100%);
    transition: 500ms all;
    z-index: 9999;

    &-inner {
      /*
      display: flex;
      flex-wrap: nowrap;*/
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-visible {
      transform: translateY(0%);
      &-gradient {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 50px;
        height: 44px;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 1)
        );
        z-index: 3;
      }
    }

    &-section-cell {
      background: url(./img/marble.jpg) center center #fff;

      display: inline-block;
      height: 68px;
    }

    &-cell {
      background: $navy;
      color: #fff;
      display: inline-block;
      height: auto;
      flex: 0 0 auto;

      padding: 20px 30px;
      transition: 500ms all;
      text-align: left;

      span {
        font-family: $bodyFont !important;
        font-size: 12px;
        display: block;
      }

      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        background-color: $salmon;
      }

      button {
        background: $navy;
        color: $salmon;
      }

      &-active {
        background: $salmon;
        color: $navy;

        button {
          background: $salmon;
          color: $navy;
        }
      }
    }
  }
}

.home-section-grid-intro {
  background: url(./img/marble.jpg) center center no-repeat #fff;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-family: $fancyFont;
    font-size: 42px;
  }
}

.home-video-meta {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;

  @media (max-width: $mobile) {
    left: 5px;
    bottom: 10px;
  }
}

.home-video-meta-type,
.home-video-meta-sub {
  padding: 10px 13px;
}

.home-video-meta-type {
  background-color: $navy;
  color: $salmon;
  font-size: 12px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;

  &[role="Anthem Lifetime Achievement"],
  &[role="Anthem Special Achievement"],
  &[role="Anthem Athlete of the Year"],
  &[role="Anthem New Icon"],
  &[role="Anthem Entertainer of the Year"] {
    background-color: #ddf777;
    color: $navy;
  }
}

.home-video-meta-sub {
  background: $salmon;
  color: $navy;
  display: block;
  font-size: 16px;
  max-width: 50%;

  &[role="Anthem Lifetime Achievement"],
  &[role="Anthem Special Achievement"],
  &[role="Anthem Athlete of the Year"],
  &[role="Anthem New Icon"],
  &[role="Anthem Entertainer of the Year"] {
    background-color: #ddf777 !important;
    color: $navy !important;
  }
}

.video-trigger {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
}

.home-section-full {
  padding: 60px 0px;

  .home-video-wrap {
    width: 600px;
    height: 600px;
    background: #000;
    margin: 0 auto;
    position: relative;

    @media (max-width: $mobile) {
      width: 100%;
      height: auto;
    }

    &-wide {
      height: inherit;
      width: 80%;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }
}

.home-section-grid {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $mobile) {
    display: block;
  }

  .home-section-grid-intro,
  .video-stack {
    flex: 0 50%;

    img {
      max-width: 100%;
      display: block;
    }

    &:before {
      content: "";
      float: left;
      padding-top: 100%;
    }

    .video-react-video {
      display: block;
    }
  }
}

/* form stuff */
.form {
  text-align: center;
  button,
  input {
    display: inline-block;
    margin: 10px 0px;
    transition: 300ms all;

    @media (max-width: $mobile) {
      font-size: 12px;
      span {
        font-size: 26px;
      }
    }
  }

  .button-disabled {
    color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }

  input[type="text"] {
    text-transform: uppercase;
    font-size: 32px;
    width: 100%;
    padding: 10px 0px;
    background: #fff;
    border: 2px solid $outline;
    text-align: center;

    @media (max-width: $mobile) {
      font-size: 22px;
    }

    &:focus {
      border: 2px solid #b8b8b8;
    }
  }

  .error {
    color: $error;
    font-style: italic;
    font-size: 12px;
  }
}

/* animations */

.animation1 {
  transform: translateY(100px);
  opacity: 0;

  animation: 1s animIn 0ms forwards;
}

.animation2 {
  transform: translateY(100px);
  opacity: 0;

  animation: 1s animIn 250ms forwards;
}

.animation3 {
  transform: translateY(100px);
  opacity: 0;

  animation: 1s animIn 500ms forwards;
}

@keyframes animIn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes countdown {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.wait-bar {
  margin-top: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  max-width: 300px;
  margin: 10px auto 100px auto;

  .wait-bar-inner {
    position: absolute;
    width: 20%;
    height: 100%;
    background: $salmon;
    left: 0px;
    animation: 2s pong infinite linear;
  }
}

@keyframes pong {
  0% {
    left: 0%;
  }

  50% {
    left: 80%;
  }

  100% {
    left: 0%;
  }
}

.video-stack {
  background: #fff;
  position: relative;

  @media (max-width: $mobile) {
  }

  .video-react-big-play-button {
    display: none !important;
  }

  &-video {
    width: 600px;
    height: 600px;
    position: absolute;
    opacity: 0;
    transition: 300ms opacity;
    pointer-events: none;
    visibility: hidden;
  }

  .video-poster {
    width: 100%;
    display: block;
  }

  .video-first {
    position: relative;
  }

  .video-follow {
    top: 0px;
    left: 0px;
    position: absolute;
  }

  .video-current {
    opacity: 1;
    pointer-events: inherit;
    visibility: visible;
  }

  .video-controls {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 1;
    transition: 300ms opacity;

    .video-button-inner {
      background: $salmon;
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      img {
        width: 30px;
        height: 30px;
      }
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0px;
      margin: 0px;
      transform: translateX(0px);
      left: 0px;
      bottom: inherit;
      top: 0px;
      background: rgba(0, 0, 0, 0);
      &:hover {
        background: rgba(0, 0, 0, 0);

        @media (max-width: $mobile) {
          background: rgba(0, 0, 0, 0);
        }
      }
    }

    &-playing {
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.home {
  .video-stack {
    height: 100%;
    background: #333;
  }
}

.home-video-linkout {
  position: absolute;
  top: 28px;
  right: 28px;
  &:hover {
    opacity: 0.5;
  }
  img {
    width: 20px;
  }
}

.playbar {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 10px;
  background: rgba(0, 0, 0, 0.25);

  &-inner {
    position: absolute;
    width: 0%;
    height: 10px;
    background: $salmon;
    transition: 60ms width;
  }
}

.video-react .video-react-fullscreen-control,
.video-react .video-react-time-control,
.video-react .video-react-mute-control,
.video-react .video-react-volume-menu-button,
.video-react .video-react-play-control {
  display: none !important;
}

.home,
.speech-page {
  .video-stack .video-controls {
    height: calc(100% - 30px);
  }

  .video-react {
    font-family: $bodyFont !important;
  }

  .video-react-control-bar {
    display: flex !important;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .video-react-paused {
    .video-react-control-bar {
      display: none !important;
    }
  }

  .video-react .video-react-slider {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .video-react .video-react-load-progress div,
  .video-react .video-react-load-progress {
    background-color: rgba(0, 0, 0, 1) !important;
  }

  .video-react .video-react-play-progress {
    background-color: $salmon;
  }

  .video-react .video-react-slider:focus {
    box-shadow: none;
  }
}

.video-react .video-react-loading-spinner {
  border: 6px solid $salmon;
}

.crop-gif {
  width: 275px;

  @media (max-width: $mobile) {
    width: 125px;
  }
}

.ReactModalPortal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999999;
}

.modal-inner {
  h3 {
    text-align: center;
    font-family: $fancyFont;
  }
  li {
    padding: 5px 0px;
  }
  .a-button {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.close-btn {
  position: absolute;
  background: none;
  right: 5px;
  top: 5px;
  padding: 0px;
  margin: 0px;
}

.footer-pre {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  &-footer {
    background: $gray;
    padding: 20px;
  }

  a {
    vertical-align: middle;
  }

  img {
    height: 30px;
    padding: 0px 0px 0px 15px;
    position: relative;
    vertical-align: middle;

    @media (max-width: $mobile) {
      height: 25px;
    }
  }
}

.footer-pre-page {
  background: #fff;
  height: $headerHeight;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-black {
  background: #000;
}

.bg-room {
  background: url("./img/2021/bg.jpg") center center no-repeat;
  background-size: cover;
}

.bg-marble {
  background: url(./img/marble.jpg) center center no-repeat #eee;
  background-size: cover;
}

.bg-spotlight {
  background: center center no-repeat #000;
  background-size: cover;
}

.browse-river-meta {
  margin-top: 10px;
  .presenter {
    font-size: 12px;
    color: #333;

    @media (max-width: $mobile) {
      font-size: 10px;
    }
  }
  .company-name {
    font-family: $boldFont;
    text-transform: uppercase;
    @media (max-width: $mobile) {
      font-size: 11px;
    }
  }

  .work-name {
    font-family: $boldFont;
  }
}

.webby-message-final {
  font-family: $fancyFont;
  font-size: 22px;
  padding-bottom: 10px;
}

.message-text {
  margin-bottom: 20px;
  padding: 10px;
  border: $salmon 1px solid;
  margin-top: 20px;
  a {
    color: $salmon;
  }
}

.award-single {
  padding-bottom: 20px;

  &:last-of-type {
    padding-bottom: 0px;
  }
}

.browse-search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

canvas {
  pointer-events: none;
}
