.root {
  background-color: #d6bfbb;
  text-align: center;
  color: black;
  padding: 30px;
}

.logo {
  display: inline-block;
  vertical-align: middle;
}

.heading {
  text-transform: uppercase;
}
